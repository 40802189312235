import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import axios from "axios";
import { io } from 'socket.io-client';


function truncateToDecimals(num, dec = 2) {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
}
function ProdPvBtRealTime() {
    const [dataEnergieCumulee, setDataEnergieCumulee] = useState()
    const [dataEnergieCumuleeTGBT, setDataEnergieCumuleeTGBT] = useState()

    const getDataFromSocket = async (data) => {
        let dataOnduleurPV
        let dataTGBTPV
        try {
            dataOnduleurPV = await axios.get(
                process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/dataPv/getEnergyPVById/' + process.env.REACT_APP_DEFAULT_OND_BT)
            setDataEnergieCumulee(dataOnduleurPV)

            dataTGBTPV = await axios.get(
                process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/dataPv/getEnergyPVById/' + process.env.REACT_APP_DEFAULT_TGBT_ID)
            setDataEnergieCumuleeTGBT(dataTGBTPV)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        const socket = io(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/`);
        socket.on("data", (data) => {
            if (data.machine.id_machine.includes("OND")) {
                getDataFromSocket(data)
            }
        });
        return () => {
            socket.disconnect();
        };

    }, []);
    return (
        <>
            <div className="page">
                <div className="page-main container-fluid-prod-pv">
                    <Header />
                    <Sidebar activeItem="prodPvBtRealTime" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Production Photovoltaîque Basse Tension en Temps Réel</h1>
                                    </div>
                                </div>
                                <div className="card-bt-container">
                                
                                <div className="card-bt">
                                            <div className="overlay-text-compteur-bt">{dataEnergieCumulee != undefined && dataEnergieCumuleeTGBT != undefined ? truncateToDecimals((dataEnergieCumulee.data.valeur) - (dataEnergieCumuleeTGBT.data.valeur)) : ""}</div>
                                            <div className="overlay-text-compteur-bt-prod">{dataEnergieCumulee != undefined ? truncateToDecimals(dataEnergieCumulee.data.valeur) : ""}</div>
                                            <div className="overlay-text-compteur-bt-conso">{dataEnergieCumuleeTGBT != undefined ? truncateToDecimals(dataEnergieCumuleeTGBT.data.valeur) : ""}</div>
                                            <div className="overlay-text-money-bt-prod">{dataEnergieCumulee != undefined ? truncateToDecimals((dataEnergieCumulee.data.valeur) * 0.291) : ""}</div>
                                            <div className="overlay-text-money-bt-conso">{dataEnergieCumuleeTGBT != undefined ? truncateToDecimals((dataEnergieCumuleeTGBT.data.valeur) * 0.291) : ""}</div>
                                            <div className="overlay-text-payer-bt ">{dataEnergieCumulee != undefined && dataEnergieCumuleeTGBT != undefined ? truncateToDecimals(((dataEnergieCumulee.data.valeur) - (dataEnergieCumuleeTGBT.data.valeur)) * (-0.291)) : ""}</div>                                   

                                    </div>
                            
                                  

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ProdPvBtRealTime;
