import React, { useState, useEffect } from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import NumericInput from 'react-numeric-input';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ParametresFacture() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    const [factType, setFactType] = useState("")
    const [info, setInfo] = useState({
        rib: "",
        consommateur: "",
        payeur: "",
        codePayeur: "",
        adresse: "",
        tel: "",
        fax: "",
        reference: "",
        ur: "",
        district: "",
    })
    const [tarif, setTarif] = useState({
        pointeJour: 0,
        pointeSoir: 0,
        pointeNuit: 0,
        pointeJourEte: 0,
        PuissanceSouscritePointeJour: 0,
        PuissanceSouscritePointeEte: 0,
        PuissanceSouscritePointeSoir: 0,
        PuissanceSouscritePointeHiver: 0,
        PuissanceSouscritePointeReduite: 0,
        MaxAppPointeJour: 0,
        MaxAppPointeEte: 0,
        MaxAppPointeSoir: 0,
        MaxAppPointeHiver: 0,
        MaxAppReduite: 0,
        PrixUnitaireDepassement:0,
        CoeK:0,
        PerteVideJour : 0,
        PerteVidePointe : 0,
        PerteVideSoir : 0,
        PerteVideNuit : 0,
    })

    const [tarifMono, setTarifMono] = useState({
        tarifM: 0,
        puissanceSouscriteJour: 0,
        maximaleAppelle: 0,
        installe: 0,
        contributionRTT: 0,
        contributionGMG: 0,
    })
    function myFormat(num) {
        return num + ' Dt';
    }

    const handleInfoChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setInfo((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target

        setTarif((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })

        setTarifMono((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })

    }
    const {
        pointeJour,
        pointeNuit,
        pointeSoir,
        pointeJourEte,
        PuissanceSouscritePointeJour,
        PuissanceSouscritePointeEte,
        PuissanceSouscritePointeSoir,
        PuissanceSouscritePointeHiver,
        PuissanceSouscritePointeReduite,
        MaxAppPointeJour,
        MaxAppPointeEte,
        MaxAppPointeSoir,
        MaxAppPointeHiver,
        MaxAppReduite,
        PrixUnitaireDepassement,
        CoeK,
        PerteVideJour,
        PerteVidePointe,
        PerteVideSoir,
        PerteVideNuit,
    } = tarif;
    const {
        tarifM,
        puissanceSouscriteJour,
        maximaleAppelle,
        installe,
        contributionRTT,
        contributionGMG
    } = tarifMono;
    const {
        rib,
        adresse,
        tel,
        fax,
        consommateur,
        ur,
        payeur,
        codePayeur,
        reference,
        district,
    } = info

    const handleTarifSubmit = async (e) => {
        e.preventDefault();
        let data_added;
        if (factType === "monotarif") {
            data_added =
            {
                "type": factType,
                "rib": rib,
                "adresse": adresse,
                "tel": tel,
                "fax": fax,
                "reference": reference,
                "codePayeur": codePayeur,
                "payeur": payeur,
                "ur": ur,
                "district": district,
                "consommateur": consommateur,
                "tarif_jour": 0,
                "tarif_soir": 0,
                "tarif_nuit": 0,
                "tarif_jour_ete": 0,
                "PuissanceSouscritePointeJour": 0,
                "PuissanceSouscritePointeEte": 0,
                "PuissanceSouscritePointeSoir": 0,
                "PuissanceSouscritePointeHiver": 0,
                "PuissanceSouscritePointeReduite": 0,
                "MaxAppPointeJour": 0,
                "MaxAppPointeEte": 0,
                "MaxAppPointeSoir": 0,
                "MaxAppPointeHiver": 0,
                "MaxAppReduite": 0,
                "PrixUnitaireDepassement":0,
                "CoeK":0,
                "PerteVideJour":0,
                "PerteVidePointe":0,
                "PerteVideSoir":0,
                "PerteVideNuit":0,
                "max_appelle": maximaleAppelle,
                "tarif": tarifM,
                "installe": installe,
                "puissance_souscrite": puissanceSouscriteJour,
                "contribution_gmg": contributionGMG,
                "contribution_rtt": contributionRTT
            }

        } else {
            data_added =
            {
                "type": factType,
                "rib": rib,
                "adresse": adresse,
                "tel": tel,
                "fax": fax,
                "reference": reference,
                "codePayeur": codePayeur,
                "payeur": payeur,
                "ur": ur,
                "district": district,
                "consommateur": consommateur,
                "tarif_jour": pointeJour,
                "tarif_soir": pointeSoir,
                "tarif_nuit": pointeNuit,
                "tarif_jour_ete": pointeJourEte,
                "PuissanceSouscritePointeJour": PuissanceSouscritePointeJour,
                "PuissanceSouscritePointeEte": PuissanceSouscritePointeEte,
                "PuissanceSouscritePointeSoir": PuissanceSouscritePointeSoir,
                "PuissanceSouscritePointeHiver": PuissanceSouscritePointeHiver,
                "PuissanceSouscritePointeReduite": PuissanceSouscritePointeReduite,
                "MaxAppPointeJour": MaxAppPointeJour,
                "MaxAppPointeEte": MaxAppPointeEte,
                "MaxAppPointeSoir": MaxAppPointeSoir,
                "MaxAppPointeHiver": MaxAppPointeHiver,
                "MaxAppReduite": MaxAppReduite,
                "PrixUnitaireDepassement":PrixUnitaireDepassement,
                "CoeK":CoeK,
                "PerteVideJour":PerteVideJour,
                "PerteVidePointe":PerteVidePointe,
                "PerteVideSoir":PerteVideSoir,
                "PerteVideNuit":PerteVideNuit,
                "max_appelle": 0,
                "tarif": 0,
                "installe": 0,
                "puissance_souscrite": 0,
                "contribution_gmg": 0,
                "contribution_rtt": 0
            }
        }

        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/facture/saveParametreFacture/', data_added,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                )
                .then((response) => console.log("response data from axios post data", response.data))

            toast.success("Vos paramètres ont été ajoutés avec succès")
        } catch (error) {
            console.log(error)
            toast.error("les tarif sont obligatoires !")
        }
    }

    async function fetchData() {
        let dataParamsFacture;
        try {
            dataParamsFacture = await axios.get(process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/facture/getParamterFacture/',
                {
                    headers: {
                      'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                    }
                  }
            )
        } catch (error) {
            console.log(error)
        }
        if (dataParamsFacture && dataParamsFacture.data && dataParamsFacture.data.type) {
            setFactType(dataParamsFacture.data.type);
            setInfo({
                rib: dataParamsFacture.data.RIB,
                adresse: dataParamsFacture.data.adresse,
                tel: dataParamsFacture.data.tel,
                fax: dataParamsFacture.data.fax,
                consommateur: dataParamsFacture.data.consommateur,
                reference: dataParamsFacture.data.reference,
                payeur: dataParamsFacture.data.payeur,
                codePayeur: dataParamsFacture.data.codePayeur,
                ur: dataParamsFacture.data.ur,
                district: dataParamsFacture.data.district,
            })
            if (dataParamsFacture.data.type === "parpointe") {
                setTarif({
                    pointeJour: dataParamsFacture.data.tarif_jour,
                    pointeSoir: dataParamsFacture.data.tarif_soir,
                    pointeNuit: dataParamsFacture.data.tarif_nuit,
                    pointeJourEte: dataParamsFacture.data.tarif_jour_ete,
                    PuissanceSouscritePointeJour: dataParamsFacture.data.PuissanceSouscritePointeJour,
                    PuissanceSouscritePointeEte: dataParamsFacture.data.PuissanceSouscritePointeEte,
                    PuissanceSouscritePointeSoir: dataParamsFacture.data.PuissanceSouscritePointeSoir,
                    PuissanceSouscritePointeHiver: dataParamsFacture.data.PuissanceSouscritePointeHiver,
                    PuissanceSouscritePointeReduite:
                        dataParamsFacture.data.PuissanceSouscritePointeReduite,
                    MaxAppPointeJour: dataParamsFacture.data.MaxAppPointeJour,
                    MaxAppPointeEte: dataParamsFacture.data.MaxAppPointeEte,
                    MaxAppPointeSoir: dataParamsFacture.data.MaxAppPointeSoir,
                    MaxAppPointeHiver: dataParamsFacture.data.MaxAppPointeHiver,
                    MaxAppReduite: dataParamsFacture.data.MaxAppReduite,
                    PrixUnitaireDepassement: dataParamsFacture.data.PrixUnitaireDepassement,
                    CoeK: dataParamsFacture.data.CoeK,
                    PerteVideJour:dataParamsFacture.data.PerteVideJour,
                    PerteVidePointe:dataParamsFacture.data.PerteVidePointe,
                    PerteVideSoir:dataParamsFacture.data.PerteVideSoir,
                    PerteVideNuit:dataParamsFacture.data.PerteVideNuit,
                })


            } else {
                setTarifMono({
                    tarifM: dataParamsFacture.data.tarif,
                    puissanceSouscriteJour: dataParamsFacture.data.puissance_souscrite,
                    maximaleAppelle: dataParamsFacture.data.max_appelle,
                    installe: dataParamsFacture.data.installe,
                    contributionRTT: dataParamsFacture.data.contribution_rtt,
                    contributionGMG: dataParamsFacture.data.contribution_gmg,
                })

            }
        }


    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="paramsFact" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Paramètres facture STEG</h1>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">Sélectionnez vos paramètres </h3>
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <label className="form-label col-md-2 col-lg-2 col-xl-3">Type facture</label>
                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                <input
                                                    type="radio"
                                                    value={factType}
                                                    name="radio"
                                                    checked={(factType == "monotarif") ? true : false}
                                                    onClick={() => {
                                                        setFactType("monotarif");
                                                    }}

                                                />

                                                <label className="form-label-checkbox">MONOTARIF</label>

                                            </div>
                                            <div className="col-md-10 col-lg-10 col-xl-2">
                                                <input
                                                    type="radio"
                                                    value={factType}
                                                    name="radio"
                                                    checked={(factType == "parpointe") ? true : false}
                                                    onClick={() => {
                                                        setFactType("parpointe");
                                                    }}
                                                />
                                                <label className="form-label-checkbox">PAR POINTE</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(factType === "parpointe") ?

                                    <div className="card">
                                        <div className="card-header border-bottom">
                                            <h3 className="card-title">Paramètres tarif PAR POINTE</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> District </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={district}
                                                                name="district"
                                                                placeholder="Saisir votre district..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Consommateur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={consommateur}
                                                                name="consommateur"
                                                                placeholder="Saisir nom consommateur..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Payeur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={payeur}
                                                                name="payeur"
                                                                placeholder="Saisir nom payeur... "
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Code Payeur (CP) </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={codePayeur}
                                                                name="codePayeur"
                                                                placeholder="Saisir CP... "
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-4"> Unité Responsable (UR) </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-2">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={ur}
                                                                name="ur"
                                                                placeholder="UR..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Réfèrence </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={reference}
                                                                name="reference"
                                                                placeholder="Saisir réfèrence..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> RIB Payeur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={rib}
                                                                name="rib"
                                                                placeholder="Saisir votre RIB..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Adresse </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={adresse}
                                                                name="adresse"
                                                                placeholder="Saisir votre adresse..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> N° Télèphone </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={tel}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                                placeholder="Saisir votre Tel..."
                                                                name="tel"
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-1"> Fax </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={fax}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                                name="fax"
                                                                placeholder="Saisir votre Fax..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Pointe Jour </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={pointeJour}
                                                                name="pointeJour"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={pointeJour => handleChange({ target: { value: pointeJour, name: 'pointeJour' } })}

                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Pointe Soir </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={pointeSoir}
                                                                name="pointeSoir"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={pointeSoir => handleChange({ target: { value: pointeSoir, name: 'pointeSoir' } })}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Pointe Nuit </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={pointeNuit}
                                                                name="pointeNuit"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={pointeNuit => handleChange({ target: { value: pointeNuit, name: 'pointeNuit' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Pointe (hiver/été) </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={pointeJourEte}
                                                                name="pointeJourEte"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={pointeJourEte => handleChange({ target: { value: pointeJourEte, name: 'pointeJourEte' } })}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Jour </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PuissanceSouscritePointeJour}
                                                                name="PuissanceSouscritePointeJour"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PuissanceSouscritePointeJour => handleChange({ target: { value: PuissanceSouscritePointeJour, name: 'PuissanceSouscritePointeJour' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Pointe été </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PuissanceSouscritePointeEte}
                                                                name="PuissanceSouscritePointeEte"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PuissanceSouscritePointeEte => handleChange({ target: { value: PuissanceSouscritePointeEte, name: 'PuissanceSouscritePointeEte' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Soir </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PuissanceSouscritePointeSoir}
                                                                name="PuissanceSouscritePointeSoir"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PuissanceSouscritePointeSoir => handleChange({ target: { value: PuissanceSouscritePointeSoir, name: 'PuissanceSouscritePointeSoir' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Hiver </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PuissanceSouscritePointeHiver}
                                                                name="PuissanceSouscritePointeHiver"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PuissanceSouscritePointeHiver => handleChange({ target: { value: PuissanceSouscritePointeHiver, name: 'PuissanceSouscritePointeHiver' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Réduite </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PuissanceSouscritePointeReduite}
                                                                name="PuissanceSouscritePointeReduite"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PuissanceSouscritePointeReduite => handleChange({ target: { value: PuissanceSouscritePointeReduite, name: 'PuissanceSouscritePointeReduite' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Max Appelée Jour </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={MaxAppPointeJour}
                                                                name="MaxAppPointeJour"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={MaxAppPointeJour => handleChange({ target: { value: MaxAppPointeJour, name: 'MaxAppPointeJour' } })}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Max Appelée Pointe Eté </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={MaxAppPointeEte}
                                                                name="MaxAppPointeEte"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={MaxAppPointeEte => handleChange({ target: { value: MaxAppPointeEte, name: 'MaxAppPointeEte' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Max Appelée Soir </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={MaxAppPointeSoir}
                                                                name="MaxAppPointeSoir"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={MaxAppPointeSoir => handleChange({ target: { value: MaxAppPointeSoir, name: 'MaxAppPointeSoir' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Max Appelée Pointe Hiver </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={MaxAppPointeHiver}
                                                                name="MaxAppPointeHiver"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={MaxAppPointeHiver => handleChange({ target: { value: MaxAppPointeHiver, name: 'MaxAppPointeHiver' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Max Appelée Réduite </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={MaxAppReduite}
                                                                name="MaxAppReduite"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={MaxAppReduite => handleChange({ target: { value: MaxAppReduite, name: 'MaxAppReduite' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <label className="form-label col-md-4 col-lg-4 col-xl-3"> Prix Unitaire de dépassement </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PrixUnitaireDepassement}
                                                                name="PrixUnitaireDepassement"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={PrixUnitaireDepassement => handleChange({ target: { value: PrixUnitaireDepassement, name: 'PrixUnitaireDepassement' } })}
                                                            />
                                                        </div>
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Coe.K </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={CoeK}
                                                                name="CoeK"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={CoeK => handleChange({ target: { value: CoeK, name: 'CoeK' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Perte à vide Jour </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PerteVideJour}
                                                                name="PerteVideJour"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PerteVideJour => handleChange({ target: { value: PerteVideJour, name: 'PerteVideJour' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Perte à vide Pointe </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PerteVidePointe}
                                                                name="PerteVidePointe"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PerteVidePointe => handleChange({ target: { value: PerteVidePointe, name: 'PerteVidePointe' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Perte à vide Soir </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PerteVideSoir}
                                                                name="PerteVideSoir"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PerteVideSoir => handleChange({ target: { value: PerteVideSoir, name: 'PerteVideSoir' } })}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Perte à vide Nuit </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={PerteVideNuit}
                                                                name="PerteVideNuit"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={PerteVideNuit => handleChange({ target: { value: PerteVideNuit, name: 'PerteVideNuit' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                                        <div className="'col-md-12 col-lg-12 col-xl-4">
                                                            <button
                                                                className="btn btn-outline-info btn-block"
                                                                type="submit"
                                                                onClick={handleTarifSubmit}
                                                            >
                                                                <i className="fa fa-calculator me-2"></i>
                                                                Valider
                                                            </button>
                                                            <ToastContainer
                                                                className="toast-container"
                                                                autoClose={4000}
                                                                closeButton={false}
                                                                theme={"colored"}
                                                                icon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>

                                }

                                {(factType === "monotarif") ?

                                    <div className="card">
                                        <div className="card-header border-bottom">
                                            <h3 className="card-title">Paramètres tarif MONOTARIF </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-6  col-xl-6">
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> District </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={district}
                                                                name="district"
                                                                placeholder="Saisir votre district..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Consommateur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={consommateur}
                                                                name="consommateur"
                                                                placeholder="Saisir nom consommateur..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Payeur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={payeur}
                                                                name="payeur"
                                                                placeholder="Saisir nom payeur... "
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Code Payeur (CP) </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-3">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={codePayeur}
                                                                name="codePayeur"
                                                                placeholder="Saisir CP... "
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-4"> Unité Responsable (UR) </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-2">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={ur}
                                                                name="ur"
                                                                placeholder="UR..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Réfèrence </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={reference}
                                                                name="reference"
                                                                placeholder="Saisir réfèrence..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> RIB Payeur </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={rib}
                                                                name="rib"
                                                                placeholder="Saisir votre RIB..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Adresse </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={adresse}
                                                                name="adresse"
                                                                placeholder="Saisir votre adresse..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> N° Télèphone </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={tel}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                                placeholder="Saisir votre Tel..."
                                                                name="tel"
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>

                                                        <label className="form-label col-md-4 col-lg-4 col-xl-1"> Fax </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-4">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                value={fax}
                                                                onInput={(e) => e.target.value = e.target.value.slice(0, 8)}
                                                                name="fax"
                                                                placeholder="Saisir votre Fax..."
                                                                onChange={handleInfoChange}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3">Tarif</label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={tarifM}
                                                                name="tarifM"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={tarifM => handleChange({ target: { value: tarifM, name: 'tarifM' } })}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Puissance Souscrite Jour</label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={puissanceSouscriteJour}
                                                                name="puissanceSouscriteJour"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={puissanceSouscriteJour => handleChange({ target: { value: puissanceSouscriteJour, name: 'puissanceSouscriteJour' } })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Maximale Appellé </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={maximaleAppelle}
                                                                name="maximaleAppelle"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={maximaleAppelle => handleChange({ target: { value: maximaleAppelle, name: 'maximaleAppelle' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Installé </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={installe}
                                                                name="installe"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                onChange={installe => handleChange({ target: { value: installe, name: 'installe' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Contribution RTT</label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={contributionRTT}
                                                                name="contributionRTT"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={contributionRTT => handleChange({ target: { value: contributionRTT, name: 'contributionRTT' } })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="form-label col-md-4 col-lg-4 col-xl-3"> Contribution GMG </label>
                                                        <div className="form-group col-md-8 col-lg-8 col-xl-9">
                                                            <NumericInput
                                                                className="form-control"
                                                                value={contributionGMG}
                                                                name="contributionGMG"
                                                                min={0}
                                                                step={0.001}
                                                                maxLength={10}
                                                                format={myFormat}
                                                                onChange={contributionGMG => handleChange({ target: { value: contributionGMG, name: 'contributionGMG' } })}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div style={{ display: 'flex', justifyContent: "flex-end", paddingTop: "50px" }}>
                                                        <div className="'col-md-12 col-lg-12 col-xl-4">
                                                            <button
                                                                className="btn btn-outline-info btn-block"
                                                                type="submit"
                                                                onClick={handleTarifSubmit}
                                                            >
                                                                <i className="fa fa-calculator me-2"></i>
                                                                Valider
                                                            </button>
                                                            <ToastContainer
                                                                className="toast-container"
                                                                autoClose={4000}
                                                                closeButton={false}
                                                                theme={"colored"}
                                                                icon={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );



}

export default ParametresFacture