import React, { useState, useEffect, useRef } from "react";
import { io } from 'socket.io-client';
import LazyLoad from 'react-lazyload';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import axios from "axios";
import throttle from 'lodash.throttle';

const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT;

function ParcMachines() {
  const token = localStorage.getItem('jwt');
  const [machineData, setMachineData] = useState([]); // Combined data with correct image order
  const Machines = useRef(JSON.parse(localStorage.getItem('machines') || '[]'));

  // Convert ArrayBuffer to Base64
  const _arrayBufferToBase64 = (buffer) => {
    if (!buffer) return null;
    const CHUNK_SIZE = 0x8000;
    let result = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i += CHUNK_SIZE) {
      const chunk = bytes.subarray(i, i + CHUNK_SIZE);
      result += String.fromCharCode.apply(null, chunk);
    }
    return window.btoa(result);
  };

  
  // Initialize machine data with placeholders
  const initializeMachineData = () => Machines.current.map(element => ({
    machine_id: element.machine_id,
    machine_name: element.machine_name,
    PTotal: 0,
    PApparante: 0,
    PMoyenne: 0,
    PFMoyenne: 0,
    VL1: 0,
    VL2: 0,
    VL3: 0,
    AL1: 0,
    AL2: 0,
    AL3: 0,
    etat: -1,
    image: null
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${ENDPOINT}/machines/getParamsMachines`,
          { headers: { 'Authorization': `Bearer ${token}` } }
        );
        const apiData = response.data;
        
        // Match images with local machines
        const combinedData = Machines.current.map(machine => {
          const apiMachine = apiData.find(apiItem => apiItem.id_machine === machine.machine_id);
          console.log(`Processing image for machine_id` , apiData);
          if (apiMachine && apiMachine.img && apiMachine.img.data) {
            console.log(`Processing image for machine_id ${machine.machine_id}`);
            return {
              ...machine,
              image: `data:${apiMachine.img.contentType};base64,${_arrayBufferToBase64(apiMachine.img.data.data)}`
            };
          } else {
            console.warn(`No image found for machine_id ${machine.machine_id}`);
            return { ...machine, image: null };
          }
        });

        setMachineData(combinedData);
      } catch (error) {
        console.error("Failed to fetch machine data:", error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const socket = io(ENDPOINT);

    const updateMachineData = throttle((data) => {
      let moy_cosphi = data.cosphi ? (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3 : 0;
      const moy_current = data.current ? (data.current.current1 + data.current.current2 + data.current.current3) / 3 : 0;

      let etat = (moy_cosphi < 0.3 ||  moy_current < 2 ) && moy_cosphi > 0 ? 0 : (moy_cosphi >= 0.3 && moy_cosphi <= 1 ? 1 : -1);

      setMachineData(prevData => prevData.map(machine => {
        if (machine.machine_id === data.machine.id_machine) {
          return {
            ...machine,
            etat: etat,
            PTotal: +data.power.power.toFixed(2),
            PApparante: +(data.powerA.powerA / 3).toFixed(2),
            PMoyenne: +(data.power.power / 3).toFixed(2),
            PFMoyenne: +moy_cosphi.toFixed(2),
            VL1: +data.voltage.voltage1.toFixed(2),
            VL2: +data.voltage.voltage2.toFixed(2),
            VL3: +data.voltage.voltage3.toFixed(2),
            AL1: +data.current.current1.toFixed(2),
            AL2: +data.current.current2.toFixed(2),
            AL3: +data.current.current3.toFixed(2)
          };
        }
        return machine;
      }));
    }, 500);

    socket.on("data", updateMachineData);

    return () => {
      socket.off("data", updateMachineData);
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="parcMachine" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <h1 className="page-title">Parc Machines</h1>
                </div>
                <div className="wrapper">
                  {machineData.map((machine, index) => (
                    <div className="card-n" key={index}>
                      <LazyLoad height={200} once>
                        {machine.image ? (
                          <img
                            src={machine.image}
                            alt='Machine'
                          />
                        ) : (
                          <img alt="" />
                        )}
                      </LazyLoad>
                      <div className='card-name'>
                        <div className='machine-name-container'>
                          <h3><b>{machine.machine_name}</b></h3>
                        </div>
                      </div>
                      <div className='card-status'>
                        {(machine.etat === -1) ?
                          <div className="wrem-2 h-6 bg-grey br-100"></div> :
                          (machine.etat === 1 ?
                            <div className="wrem-2 h-6 bg-green br-100"></div> :
                            <div className="wrem-2 h-6 bg-danger br-100"></div>
                          )
                        }
                      </div>
                      <div className="info">
                        <p>P. Totale: {machine.PTotal} W</p>
                        <p>Courant L1: {machine.AL1} A</p>
                        <p>Courant L2: {machine.AL2} A</p>
                        <p>Courant L3: {machine.AL3} A</p>
                        <p>________________________________</p>
                        <p>P. Apparente: {machine.PApparante} VA</p>
                        <p>V1: {machine.VL1} V</p>
                        <p>V2: {machine.VL2} V</p>
                        <p>V3: {machine.VL3} V</p>
                        <p>________________________________</p>
                        <p>P. Moyenne: {machine.PMoyenne} W</p>
                        <p>PF. Moyenne (cosphi): {machine.PFMoyenne}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ParcMachines;